<script lang="ts">
    import { formatDistanceToNow } from 'date-fns';
    import toast from 'svelte-french-toast';
    import * as DropdownMenu from '$lib/components/ui/dropdown-menu';
    import * as Dialog from '$lib/components/ui/dialog/index.js';
    import * as Select from '$lib/components/ui/select/index.js';
    import { Button } from '$lib/components/ui/button/index.js';
    import { Input } from '$lib/components/ui/input/index.js';
    import { Label } from '$lib/components/ui/label/index.js';
    import EllipsisHorizontalIcon from '@/Icons/EllipsisHorizontalIcon.svelte';

    export let post: any;
    export let currentUser: any;
    export let data: any;
    export let siteSettings: any;
    export let PostsPaginator: any;
    export let activePage: string;
    export let user: any;
    export let reportStatuses: [];
    export let minPostDeletionLimit: number;
    export let userLists = {
        blocked: '',
        following: '',
    };

    let showPostSaleDialog = false;
    let showPostDeleteDialog = false;
    let showListManagementDialog = false;
    let showReportDialog = false;

    let postSaleDialogData: any = {
        post_id: '',
        price: '',
        action: '',
        price_type: '',
        validity: '',
    };

    let listManagementAction = '';
    let listManagementUserId = '';
    let reportReason = '';
    let reportDetails = '';

    const priceTypes = [
        { value: 'auction', label: 'Auction' },
        { value: 'static', label: 'Static' },
    ];

    $: reportReasonOptions = reportStatuses.map((status) => ({
        value: status,
        label: status,
    }));

    const csrfTokenMeta = document.querySelector('meta[name="csrf-token"]');
    const csrfToken = csrfTokenMeta ? csrfTokenMeta.getAttribute('content') || '' : '';

    function isPostExpiring(expireDate: string | null): boolean {
        return expireDate !== null && new Date(expireDate) > new Date();
    }

    function isPostScheduled(releaseDate: string | null): boolean {
        return releaseDate !== null && new Date(releaseDate) > new Date();
    }

    function handleShowReportBox() {
        showReportDialog = true;
    }

    function handleReasonChange(selected: string) {
        if (selected) {
            reportReason = selected;
        }
    }

    async function handleSubmitReport() {
        if (!reportReason) {
            toast.error('Please select a reason for the report.');
            return;
        }

        try {
            const formData = new FormData();
            formData.append('user_id', post.user.id);
            formData.append('post_id', post.id);
            formData.append('type', reportReason.value);
            formData.append('details', reportDetails);

            const response = await fetch(`${app.baseUrl}/report/content`, {
                method: 'POST',
                headers: {
                    'X-CSRF-TOKEN': csrfToken,
                    'X-Requested-With': 'XMLHttpRequest',
                },
                body: formData,
            });

            const result = await response.json();

            if (response.ok) {
                toast.success('Report submitted');
                showReportDialog = false;
                reportReason = '';
                reportDetails = '';
            } else {
                throw new Error(result.message || 'An error occurred');
            }
        } catch (error) {
            console.error('Report error:', error);
            toast.error(error.message);
        }
    }

    async function handleShowListManagementConfirmation(type: string, user_id: string) {
        listManagementAction = type;
        listManagementUserId = user_id;
        showListManagementDialog = true;
    }

    async function handleListManagement() {
        try {
            if (!userLists?.following || !userLists?.blocked) {
                throw new Error('Required lists not found');
            }

            let list_id;
            let innerType;
            let actionMessage;

            if (listManagementAction === 'unfollow') {
                list_id = userLists.following;
                innerType = 'remove';
                actionMessage = 'unfollowing';
            } else if (listManagementAction === 'block') {
                list_id = userLists.blocked;
                innerType = 'add';
                actionMessage = 'blocking';
            } else {
                throw new Error('Invalid action type');
            }

            console.log(`Starting ${actionMessage} process...`);

            const success = await updateListMember(list_id, listManagementUserId, innerType, false);
            if (success) {
                showListManagementDialog = false;
                console.log(`${actionMessage} successful`);
            }
        } catch (error) {
            console.error('List management error:', error);
            toast.error(error.message || `Failed to ${listManagementAction} user`);
        }
    }

    async function updateListMember(list_id: string, user_id: string, type: string, showMessages: boolean = true) {
        try {
            const data = new URLSearchParams();
            data.append('list_id', list_id);
            data.append('user_id', user_id);
            data.append('returnData', showMessages.toString());

            const requestMethod = type === 'add' ? 'POST' : 'DELETE';
            const requestUrl =
                type === 'add' ? `${app.baseUrl}/my/lists/members/save` : `${app.baseUrl}/my/lists/members/delete`;

            console.log('Making request:', { requestUrl, requestMethod, data: Object.fromEntries(data) });

            const response = await fetch(requestUrl, {
                method: requestMethod,
                headers: {
                    'X-CSRF-TOKEN': csrfToken,
                    'X-Requested-With': 'XMLHttpRequest',
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: data,
                credentials: 'same-origin',
            });

            const result = await response.json();
            console.log('Response:', result);

            if (!response.ok) {
                throw new Error(result.message || result.errors?.[0] || 'Server returned an error');
            }

            if (result.message) {
                toast.success(result.message);
            } else {
                toast.success(type === 'add' ? 'Successfully blocked user' : 'Successfully unfollowed user');
            }

            if (type === 'remove') {
                data.isFollowing = false;
            }

            showListManagementDialog = false;
            return true;
        } catch (error) {
            console.error('Update list member error:', error);
            const errorMessage = error.responseJSON?.message || error.message || 'An error occurred';
            toast.error(errorMessage);
            throw error;
        }
    }

    function handleListPostForSale() {
        const post_id = post.id;
        showPostSaleDialog = true;
        postSaleDialogData = {
            post_id: post_id,
            price: '',
            price_type: '',
            validity: '',
            action: 'create',
        };
    }

    function renderPostSalePriceModal(post_id: string, price: number, sellingType: string, validity: string) {
        console.log('Opening update dialog with:', { post_id, price, sellingType, validity });
        showPostSaleDialog = true;

        // Ensure proper data structure for update
        postSaleDialogData = {
            post_id: post_id,
            price: price,
            price_type: sellingType,
            validity: validity,
            action: 'update',
        };

        console.log('Dialog data set for update:', postSaleDialogData);
    }

    async function handleSubmitPostForSale() {
        console.log('Raw postSaleDialogData:', postSaleDialogData);

        const sanitizedData = {
            actionType: postSaleDialogData.action,
            post_id: typeof postSaleDialogData.post_id === 'object' ? post.id : postSaleDialogData.post_id,
            price: postSaleDialogData.price,
            priceType:
                typeof postSaleDialogData.price_type === 'object'
                    ? postSaleDialogData.price_type.value
                    : postSaleDialogData.price_type,
            validity: postSaleDialogData.validity,
        };

        console.log('Sanitized data for submission:', sanitizedData);

        if (!sanitizedData.price || !sanitizedData.priceType || !sanitizedData.validity) {
            toast.error('Please fill up all the input fields.');
            return;
        }

        const selectedDate = new Date(sanitizedData.validity);
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        if (selectedDate <= today) {
            toast.error('The selected date must be greater than today.');
            return;
        }

        try {
            const response = await fetch(`${app.baseUrl}/post-market/store`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': csrfToken,
                },
                body: JSON.stringify(sanitizedData),
            });

            const result = await response.json();

            if (response.ok) {
                toast.success(result.message);
                showPostSaleDialog = false;
            } else {
                throw new Error(result.message || 'An error occurred');
            }
        } catch (error) {
            console.error('Error details:', error);
            toast.error(error.message);
        }
    }

    async function handleDeleteFromMarket() {
        if (confirm('Are you sure you want to delete the post?')) {
            try {
                const sanitizedData = {
                    item_id: typeof postSaleDialogData.post_id === 'object' ? post.id : postSaleDialogData.post_id,
                    itemType: 'post',
                };

                const response = await fetch(`${app.baseUrl}/market/delete`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-TOKEN': csrfToken,
                        'X-Requested-With': 'XMLHttpRequest',
                        Accept: 'application/json',
                    },
                    body: JSON.stringify(sanitizedData),
                });

                showPostSaleDialog = false;
                data.isPostListedForSale = 'no';
                data.postMarketPrice = null;
                post.price_type = null;
                post.expire_date = null;

                toast.success('The post has been removed from the market');
            } catch (error) {
                console.error('Delete error:', error);
                toast.error('An error occurred while deleting the post');
            }
        }
    }

    function confirmPostRemoval(post_id: string) {
        post.postID = post_id;
        postSaleDialogData.post_id = post_id;
        showPostDeleteDialog = true;
    }

    async function removePost() {
        try {
            const params = new URLSearchParams();
            params.append('id', postSaleDialogData.post_id);

            const response = await fetch(`${app.baseUrl}/posts/delete`, {
                method: 'DELETE',
                headers: {
                    'X-CSRF-TOKEN': csrfToken,
                    'X-Requested-With': 'XMLHttpRequest',
                    Accept: 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: params,
                credentials: 'same-origin',
            });

            const result = await response.json();

            if (response.ok && result.success) {
                showPostDeleteDialog = false;
                if (activePage !== 'post') {
                    const postElement = document.querySelector(`*[data-postID="${postSaleDialogData.post_id}"]`);
                    if (postElement) {
                        postElement.style.transition = 'opacity 0.3s ease';
                        postElement.style.opacity = '0';
                        setTimeout(() => postElement.remove(), 300);
                    }
                } else {
                    if (document.referrer.indexOf('feed') > 0) {
                        window.location.href = `${app.baseUrl}/feed`;
                    } else {
                        window.location.href = document.referrer;
                    }
                }
                toast.success(result.message);
            } else {
                throw new Error(result.errors ? result.errors[0] : 'An error occurred');
            }
        } catch (error) {
            console.error('Delete error:', error);
            showPostDeleteDialog = false;
            toast.error(error.message);
        }
    }

    function handleGoToPostPageKeepingNav() {
        PostsPaginator.goToPostPageKeepingNav(post.id, post.postPage, `/posts/${post.id}/${post.user.username}`);
    }

    function handleShareOrCopyLink() {
        const postUrl = `${window.location.origin}/posts/${post.id}/${post.user.username}`;

        navigator.clipboard
            .writeText(postUrl)
            .then(() => toast.success('Link copied to clipboard!'))
            .catch(() => toast.error('Failed to copy the link'));
    }

    async function handleTogglePostBookmark() {
        const action = data.isPostBookmarked ? 'remove' : 'add';

        try {
            const formData = new FormData();
            formData.append('action', action);
            formData.append('id', post.id);

            const response = await fetch(`/posts/bookmark`, {
                method: 'POST',
                headers: {
                    'X-CSRF-TOKEN': csrfToken,
                    'X-Requested-With': 'XMLHttpRequest',
                },
                body: formData,
                credentials: 'same-origin',
            });

            const result = await response.json();

            if (result.success) {
                data.isPostBookmarked = !data.isPostBookmarked;
                toast.success(result.message);
            } else {
                toast.error(result.errors ? result.errors[0] : 'An error occurred');
            }
        } catch (error) {
            console.error('Bookmark error:', error);
            toast.error('Failed to update bookmark');
        }
    }
</script>

<div
    class="post-header pl-3 pr-3"
    class:converting="{currentUser &&
        ((post.user_id === currentUser.id && post.status == 0 && data.hasVideoAttachment) ||
            (currentUser.role_id === 1 && post.status == 0 && data.hasVideoAttachment))}"
>
    <div class="flex">
        <div class="avatar-wrapper">
            <img class="avatar aspect-square rounded-full" src="{post.user.avatar}" alt="{post.user.name}" />
        </div>
        <div class="post-details w-100 pl-2">
            <div class="flex justify-between">
                <div>
                    <div class="mt-1 font-bold">
                        <a href="/{post.user.username}" class="text-dark-r dark:!text-white">{post.user.name}</a>
                    </div>
                    <div class="-mt-1">
                        <a href="/{post.user.username}" class="text-dark-r text-hover">@{post.user.username}</a>
                    </div>
                </div>

                <div class="flex">
                    {#if post.expire_date}
                        <div class="pr-md-3 pr-3">
                            <span
                                class="badge badge-pill bg-gradient-faded-primary"
                                class:tooltip="{isPostExpiring(post.expire_date)}"
                                data-placement="bottom"
                                title="{isPostExpiring(post.expire_date)
                                    ? `Expiring in ${formatDistanceToNow(new Date(post.expire_date))}`
                                    : ''}"
                            >
                                {isPostExpiring(post.expire_date) ? 'Expiring' : 'Expired'}
                            </span>
                        </div>
                    {/if}

                    {#if currentUser && post.release_date && currentUser.id === post.user_id && isPostScheduled(post.release_date)}
                        <div class="pr-md-3 pr-3">
                            <span
                                class="badge badge-pill bg-gradient-faded-primary"
                                class:tooltip="{isPostScheduled(post.release_date)}"
                                data-placement="bottom"
                                title="{isPostScheduled(post.release_date)
                                    ? `Posting in ${formatDistanceToNow(new Date(post.release_date))}`
                                    : ''}"
                            >
                                Scheduled
                            </span>
                        </div>
                    {/if}

                    {#if currentUser && post.user_id === currentUser.id && post.price > 0}
                        <div class="pr-md-3 flex items-center pr-3">
                            <span class="badge badge-pill bg-gradient-faded-primary">PPV</span>
                        </div>
                    {/if}

                    <div class="pr-md-3 flex items-center pr-3">
                        <a
                            class="text-dark-r text-hover"
                            on:click="{() => handleGoToPostPageKeepingNav()}"
                            href="javascript:void(0)"
                        >
                            {formatDistanceToNow(new Date(post.created_at))}
                        </a>
                    </div>
                    <DropdownMenu.Root>
                        <DropdownMenu.Trigger>
                            <div class="rounded-md border px-1 dark:!border-neutral-800">
                                <EllipsisHorizontalIcon />
                            </div>
                        </DropdownMenu.Trigger>
                        <DropdownMenu.Content>
                            <DropdownMenu.Item on:click="{handleShareOrCopyLink}">Copy post link</DropdownMenu.Item>

                            {#if currentUser}
                                <DropdownMenu.Item on:click="{handleTogglePostBookmark}">
                                    {data.isPostBookmarked ? 'Remove the bookmark' : 'Bookmark this post'}
                                </DropdownMenu.Item>

                                {#if currentUser.id !== post.user.id}
                                    <DropdownMenu.Separator />
                                    <DropdownMenu.Item
                                        on:click="{() =>
                                            handleShowListManagementConfirmation('unfollow', post.user.id)}"
                                    >
                                        Unfollow
                                    </DropdownMenu.Item>
                                    <DropdownMenu.Item
                                        on:click="{() => handleShowListManagementConfirmation('block', post.user.id)}"
                                    >
                                        Block
                                    </DropdownMenu.Item>
                                    <DropdownMenu.Item on:click="{handleShowReportBox}">Report</DropdownMenu.Item>
                                {/if}

                                {#if currentUser.id === post.user.id}
                                    <DropdownMenu.Separator />
                                    {#if data.isPostListedForSale === 'yes'}
                                        <DropdownMenu.Item
                                            on:click="{() =>
                                                renderPostSalePriceModal(
                                                    post.id,
                                                    data.postMarketPrice,
                                                    post.price_type,
                                                    post.expire_date
                                                )}"
                                        >
                                            Update sale listing
                                        </DropdownMenu.Item>
                                    {:else}
                                        <DropdownMenu.Item on:click="{handleListPostForSale}">
                                            List For Sale
                                        </DropdownMenu.Item>
                                    {/if}
                                    <DropdownMenu.Separator />
                                    <DropdownMenu.Item>
                                        <a href="/posts/edit/{post.id}">Edit post</a>
                                    </DropdownMenu.Item>
                                    {#if !minPostDeletionLimit || (minPostDeletionLimit > 0 && post.user.posts_count > minPostDeletionLimit)}
                                        <DropdownMenu.Item on:click="{() => confirmPostRemoval(post.id)}">
                                            Delete post
                                        </DropdownMenu.Item>
                                    {/if}
                                {/if}
                            {/if}
                        </DropdownMenu.Content>
                    </DropdownMenu.Root>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Post Sale Dialog -->
<Dialog.Root bind:open="{showPostSaleDialog}">
    <Dialog.Content class="sm:max-w-[425px]">
        <Dialog.Header>
            <Dialog.Title>
                {postSaleDialogData.action === 'update' ? 'Update Sale Listing' : 'List Post For Sale'}
            </Dialog.Title>
            <Dialog.Description>
                {postSaleDialogData.action === 'update'
                    ? 'Update the sale details for your post.'
                    : 'Set the details to list your post for sale.'}
            </Dialog.Description>
        </Dialog.Header>
        <div class="grid gap-4 py-4">
            <div class="grid grid-cols-4 items-center gap-4">
                <Label for="post_price" class="text-right">Price</Label>
                <Input
                    id="post_price"
                    type="number"
                    step="0.1"
                    bind:value="{postSaleDialogData.price}"
                    class="col-span-3"
                    required
                />
            </div>
            <div class="grid grid-cols-4 items-center gap-4">
                <Label for="price_type_selector" class="text-right">Price Type</Label>
                <div class="col-span-3">
                    <Select.Root bind:selected="{postSaleDialogData.price_type}" portal="{null}">
                        <Select.Trigger class="w-full">
                            <Select.Value placeholder="Select a price type" />
                        </Select.Trigger>
                        <Select.Content>
                            <Select.Group>
                                <Select.Label>Price Types</Select.Label>
                                {#each priceTypes as priceType}
                                    <Select.Item
                                        value="{priceType.value}"
                                        label="{priceType.label}"
                                        on:click="{() => (postSaleDialogData.price_type = priceType.value)}"
                                    >
                                        {priceType.label}
                                    </Select.Item>
                                {/each}
                            </Select.Group>
                        </Select.Content>
                        <Select.Input name="priceType" />
                    </Select.Root>
                </div>
            </div>
            <div class="grid grid-cols-4 items-center gap-4">
                <Label for="validity_as_date" class="text-right">Validity</Label>
                <Input
                    id="validity_as_date"
                    type="datetime-local"
                    bind:value="{postSaleDialogData.validity}"
                    class="col-span-3"
                    required
                />
            </div>
        </div>
        <Dialog.Footer>
            {#if postSaleDialogData.action === 'update'}
                <Button on:click="{handleDeleteFromMarket}" variant="destructive">Delete From Market</Button>
            {/if}
            <Button on:click="{handleSubmitPostForSale}" type="submit">
                {postSaleDialogData.action === 'update' ? 'Update' : 'Submit'}
            </Button>
        </Dialog.Footer>
    </Dialog.Content>
</Dialog.Root>

<!-- Post Delete Dialog -->
<Dialog.Root bind:open="{showPostDeleteDialog}">
    <Dialog.Content class="sm:max-w-[425px]">
        <Dialog.Header>
            <Dialog.Title>Delete post</Dialog.Title>
            <Dialog.Description>Are you sure you want to delete this post?</Dialog.Description>
        </Dialog.Header>
        <Dialog.Footer>
            <Button on:click="{() => (showPostDeleteDialog = false)}" variant="outline">Cancel</Button>
            <Button on:click="{removePost}" variant="destructive">Delete</Button>
        </Dialog.Footer>
    </Dialog.Content>
</Dialog.Root>

<!-- List Management Dialog -->
<Dialog.Root bind:open="{showListManagementDialog}">
    <Dialog.Content class="sm:max-w-[425px]">
        <Dialog.Header>
            <Dialog.Title>
                {listManagementAction === 'block' ? 'Block user' : 'Unfollow user'}
            </Dialog.Title>
        </Dialog.Header>
        <div class="py-4">
            {#if listManagementAction === 'block'}
                <p>Are you sure you want to block this user? Your posts will be mutually hidden and chats disabled.</p>
            {:else}
                <p>Are you sure you want to unfollow this user? His posts will be hidden from your feed.</p>
                <p>You can follow back any time later from the lists module.</p>
            {/if}
        </div>
        <Dialog.Footer>
            <Dialog.Close>
                <Button variant="outline">Cancel</Button>
            </Dialog.Close>
            <!-- Remove the direct state modification from the confirm button -->
            <Button
                variant="destructive"
                on:click="{async () => {
                    try {
                        await handleListManagement();
                    } catch (error) {
                        console.error('List management error:', error);
                    }
                }}"
            >
                Confirm
            </Button>
        </Dialog.Footer>
    </Dialog.Content>
</Dialog.Root>

<!-- Report Dialog -->
<Dialog.Root bind:open="{showReportDialog}">
    <Dialog.Content class="sm:max-w-[425px]">
        <Dialog.Header>
            <Dialog.Title>Report user or post</Dialog.Title>
        </Dialog.Header>
        <div class="py-4">
            <div class="mb-4">
                <Label for="reasonExamples">Reason</Label>
                <Select.Root
                    selected="{reportReason}"
                    items="{reportReasonOptions}"
                    onSelectedChange="{handleReasonChange}"
                >
                    <Select.Trigger class="w-full">
                        <Select.Value placeholder="Select a reason" />
                    </Select.Trigger>
                    <Select.Content>
                        {#each reportReasonOptions as option}
                            <Select.Item value="{option.value}" label="{option.label}">
                                {option.label}
                            </Select.Item>
                        {/each}
                    </Select.Content>
                </Select.Root>
            </div>
            <div>
                <Label for="post_report_details">Details</Label>
                <textarea
                    id="post_report_details"
                    bind:value="{reportDetails}"
                    class="w-full rounded border p-2"
                    rows="3"
                ></textarea>
            </div>
        </div>
        <Dialog.Footer>
            <Button on:click="{() => (showReportDialog = false)}" variant="outline">Cancel</Button>
            <Button on:click="{handleSubmitReport}" variant="destructive">Report</Button>
        </Dialog.Footer>
    </Dialog.Content>
</Dialog.Root>
